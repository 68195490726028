import React from 'react'
import { Dropdown, Input, Icon } from 'semantic-ui-react'

const XDropdown = ({
  placeholder,
  options,
  selectedOption,
  disabled,
  onChange
}) => {
  const handleReset = selectedOption => {
    onChange(null, { value: selectedOption.value, clear: true })
  }

  return (
    <Input fluid size='large' style={{ marginBottom: 5 }}>
      <Dropdown
        value={selectedOption ? selectedOption.value : null}
        options={options}
        onChange={onChange}
        disabled={disabled}
        lazyLoad='true'
        placeholder={placeholder}
        fluid
        search
        selection
        closeOnChange
      />
      {selectedOption && (
        <Icon
          link
          className='ta-close reset-icon'
          color='red'
          // size='large'
          onClick={() => handleReset(selectedOption)}
        />
      )}
    </Input>
  )
}

export default XDropdown
