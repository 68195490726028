import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'

import Layout from '../components/Layouts/FiltersLayout'
import ItemGroups from '../components/Items/ItemGroups'
import { getRawImage } from '../utils/images'

const FiltersPage = ({ pageContext, data, t }) => {
  // console.log('pageContext', pageContext)
  // console.log('data', data)
  // console.log('firestoreCountry', data.firestoreCountry)
  // console.log('firestoreRegion', data.firestoreRegion)
  // console.log('firestoreLocation', data.firestoreLocation)

  const country = pageContext.country && data.firestoreCountry
  const region = pageContext.region && data.firestoreRegion
  const location = pageContext.location && data.firestoreLocation
  const service = pageContext.service

  const dataObject = pageContext.location
    ? location
    : pageContext.region
    ? region
    : country
  const dataObjectType = pageContext.location
    ? 'location'
    : pageContext.region
    ? 'region'
    : 'country'
  const { images, seo } = dataObject || {}

  let cloudinary = null
  if (images && images.headers) {
    cloudinary = images.headers[0].cloudinary
  } else if (images && images.standard) {
    cloudinary = images.standard[0].cloudinary
  } else if (images && images[0]) {
    cloudinary = images[0].cloudinary
  }

  let title = null
  let description = null

  // dataObject is specified only for pages which are generated from TourBase API data
  if (dataObject) {
    if (service === 'all-services') {
      if (dataObjectType === 'country') {
        // '/countries' TourBase API endpoint has two objects for Country SEO fields
        // one for /reiseziele page (seo.filters) and one for /laender page (seo.info)
        title = seo.filter.htmlTitle
        description = seo.filter.description
      } else {
        title = seo.htmlTitle
        description = seo.description
      }
    } else {
      title = seo[service].htmlTitle
      description = seo[service].description
    }
  }
  // general title and description from translations
  // on pages '/reiseziele/de/alles/['', rundreisen, hotels, ausfluge]' - no country/region/location specified
  else {
    title = t(`seo.destinations.all.${service || 'all-services'}.title`)
    description = t(
      `seo.destinations.all.${service || 'all-services'}.description`
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>

        <meta name='description' content={description} />
        {cloudinary && (
          <meta name='image' content={getRawImage({ cloudinary })} />
        )}

        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        {cloudinary && (
          <meta property='og:image' content={getRawImage({ cloudinary })} />
        )}

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='Tischler Reisen' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        {cloudinary && (
          <meta name='twitter:image' content={getRawImage({ cloudinary })} />
        )}
      </Helmet>

      <Layout pageContext={pageContext} data={data}>
        <ItemGroups
          dataObject={dataObject}
          isCountry={!region}
          service={service}
          dataObjectType={dataObjectType}
        />
      </Layout>
    </>
  )
}

export default withTranslation()(FiltersPage)

/**************************************************************
 * GRAPHQL
 **************************************************************/
export const pageQuery = graphql`
  query FiltersPage(
    $country: String
    $region: String
    $location: String
    $lang: String!
  ) {
    firestoreCountry(title: { eq: $country }, lang: { eq: $lang }) {
      title
      slug
      countryRegion
      countryRegionSlug
      lead
      lang
      seo {
        filter {
          htmlTitle
          description
          h1
          text
        }
        hotels {
          htmlTitle
          description
          h1
          text
        }
        trips {
          htmlTitle
          description
          h1
          text
        }
        tours {
          htmlTitle
          description
          h1
          text
        }
      }
      images {
        headers {
          id
          cloudinary
        }
      }
    }
    firestoreRegion(title: { eq: $region }, lang: { eq: $lang }) {
      title
      slug
      countryRegion
      countryRegionSlug
      lead
      lang
      seo {
        htmlTitle
        description
        h1
        text
        hotels {
          htmlTitle
          description
          h1
          text
        }
        trips {
          htmlTitle
          description
          h1
          text
        }
        tours {
          htmlTitle
          description
          h1
          text
        }
      }
      images {
        standard {
          id
          cloudinary
        }
      }
    }
    firestoreLocation(title: { eq: $location }, lang: { eq: $lang }) {
      title
      slug
      countryRegion
      countryRegionSlug
      lead
      lang
      seo {
        htmlTitle
        description
        h1
        text
        hotels {
          htmlTitle
          description
          h1
          text
        }
        trips {
          htmlTitle
          description
          h1
          text
        }
        tours {
          htmlTitle
          description
          h1
          text
        }
      }
      images {
        id
        cloudinary
      }
    }
    allFirestoreTour(
      filter: { country: { eq: $country }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          lang
          title
          country
          location
          region
          intro
          categories
          days
          collection
          coverImage
          slug
          countryRegion
          countryRegionSlug
          countrySlug
          days
          nights
        }
      }
    }
    allFirestoreTrip(
      filter: { country: { eq: $country }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          lang
          title
          country
          location
          region
          intro
          collection
          coverImage
          slug
          countryRegion
          countryRegionSlug
          countrySlug
          categories
        }
      }
    }
    allFirestoreHotel(
      filter: { country: { eq: $country }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          lang
          title
          country
          location
          region
          intro
          categories
          coverImage
          stars
          collection
          slug
          countryRegion
          countryRegionSlug
          countrySlug
        }
      }
    }
    allFirestoreCategory(
      filter: { lang: { eq: $lang } }
      sort: { fields: [jsonOrder], order: ASC }
    ) {
      edges {
        node {
          id
          title
          service
          type
          lang
        }
      }
    }
    allFirestoreCountry(
      filter: { lang: { eq: $lang } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          countryRegion
          countryRegionSlug
          slug
          lang
        }
      }
    }
    allFirestoreRegion(
      filter: { lang: { eq: $lang } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          country
          countrySlug
          countryRegion
          countryRegionSlug
          lang
        }
      }
    }
    allFirestoreLocation(
      filter: { lang: { eq: $lang } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          country
          countrySlug
          countryRegion
          countryRegionSlug
          region
          regionSlug
          lang
        }
      }
    }
  }
`
