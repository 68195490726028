import React from 'react'
import { Card, Label } from 'semantic-ui-react/dist/commonjs'
import TextTruncate from 'react-text-truncate'
import Img from 'gatsby-image'
import { withTranslation } from 'react-i18next'

import { Link } from '../../locales'
import { colorRed } from '../../utils/constants'
import { getFluidImage } from '../../utils/images'
import { unescape, stripHtml } from '../../utils/helpers'
import WishlistButton from '../Wishlist/WishlistButton'
import StarCounter from '../Common/StarCounter'

const ItemCard = ({ node, t, i18n }) => {
  const {
    title,
    alt,
    collection,
    country,
    region,
    location,
    intro,
    coverImage,
    slug,
    countrySlug,
    countryRegionSlug,
    stars,
    categories,
    days,
    nights
  } = node
  const url = `/country-regions/${countryRegionSlug}/destinations/${countrySlug}/${collection}/${slug}`

  let duration = null
  if (collection === 'tours') {
    duration = `${days} ${t('tour-details.days')} / ${nights} ${t(
      'tour-details.nights'
    )} `
  }

  return (
    <Card fluid>
      <Link to={url} lang={i18n.language} aria-label={title}>
        <Img
          loading='lazy'
          fadeIn={false}
          fluid={getFluidImage({ cloudinary: coverImage, maxWidth: 500 })}
          alt={alt || title}
        />
      </Link>

      <Card.Content>
        <Card.Meta>
          {categories && (
            <div style={{ textAlign: 'center' }}>
              {categories.map(category => (
                <Label
                  key={category}
                  as='a'
                  color='red'
                  style={{ marginBottom: 5, cursor: 'default' }}
                  size='tiny'
                >
                  {category}
                </Label>
              ))}
            </div>
          )}

          <div className='nowrap'>
            <i className='icon ta-locationmark' /> {country} | {region} |{' '}
            {location}
          </div>

          {duration && (
            <div className='nowrap'>
              <i className='icon ta-time' /> {duration}
            </div>
          )}
        </Card.Meta>
        <br />
        <Card.Header>
          <Link to={url} lang={i18n.language} aria-label={title}>
            <div className='nowrap'>{stripHtml(unescape(title))}</div>
          </Link>
          <StarCounter count={stars} color={colorRed} />
        </Card.Header>
        <Card.Description style={{ height: 70, overflow: 'hidden' }}>
          <TextTruncate text={stripHtml(unescape(intro))} line={2} />
        </Card.Description>
      </Card.Content>

      <WishlistButton
        item={{
          title,
          slug,
          intro,
          collection,
          country,
          countrySlug,
          countryRegionSlug,
          coverImage
        }}
      />
    </Card>
  )
}

export default withTranslation()(ItemCard)
